import React, { useContext } from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { I18nContext } from '../../config/i18n';
import { NavigationStyles } from './Navigation.style';
import DrawerBody from '../drawer-body/DrawerBody';
import configBlockedRoutes from '../router/ConfigBlockedRoutes';
import DrawerMenu from '../drawer-menu/DrawerMenu';
import Drawer from '../drawer/Drawer';
import { pushInteraction } from '../../data-layer/interaction';
import { getPageName } from '../../data-layer/modeling';

const blockedRoutes = configBlockedRoutes();

const Navigation = ({ menuActive, setMenuActive, isMobile }) => {
  const { pathname } = useLocation();
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  const classes = NavigationStyles();

  function openPage(page) {
    pushInteraction('navigation', 'side menu', getPageName(page));
    history.push(page);
    if (!isMobile) return;
    setMenuActive(false);
  }

  const allNavigationItems = [
    {
      icon: 'outlined-finance-shoppingcartproduct',
      name: translate('sales.page.name'),
      onSelect: () => { openPage('/sales'); },
      selected: pathname.indexOf('/sales') > -1,
      blockName: '/sales',
    },
    {
      icon: 'outlined-social-network',
      name: translate('customers.page.name'),
      onSelect: () => { openPage('/customers'); },
      selected: pathname === '/customers',
      blockName: '/customers',
    },
    {
      icon: 'outlined-product-promotionproduct',
      name: translate('coupons.page.name'),
      onSelect: () => { openPage('/coupons'); },
      selected: pathname.indexOf('/coupons') > -1,
      blockName: '/coupons',
    },
    {
      icon: 'outlined-product-abandonedbag',
      name: translate('abandoned_orders.page.name'),
      onSelect: () => { openPage('/abandoned'); },
      selected: pathname === '/abandoned',
      blockName: '/abandoned',
    },
  ];

  function handleClassName() {
    return !isMobile ? clsx(classes.drawer, {
      [classes.drawerOpen]: menuActive,
      [classes.drawerClose]: !menuActive,
    }) : false;
  }

  function handleClasses() {
    return !isMobile ? {
      paper: clsx({
        [classes.drawerOpen]: menuActive,
        [classes.drawerClose]: !menuActive,
      }),
    } : { paper: 'natds1' };
  }

  function compareNavigationItems(arrParam) {
    const arrToReturn = [];
    arrParam.forEach((item) => {
      if (blockedRoutes && blockedRoutes.some((route) => route === item.blockName)) return;
      arrToReturn.push(item);
    });
    return arrToReturn;
  }

  function menuItens() {
    return compareNavigationItems(allNavigationItems);
  }

  return (
    <Drawer
      anchor="left"
      className={handleClassName()}
      classes={handleClasses()}
      open={menuActive}
      onClose={() => setMenuActive(false)}
      variant={isMobile ? 'temporary' : 'permanent'}
    >
      <DrawerBody>
        <DrawerMenu
          list={menuItens()}
        />
      </DrawerBody>
    </Drawer>
  );
};

export default Navigation;
